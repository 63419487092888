import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./organisatieeffectiviteit.scss"
import kantoor from "../images/kantoor.jpg"
import bgcase from "../images/zippercasebgtrans.png"
import workwalker from "../images/workwalker.png"

const Organisatieeffectiviteit = () => {

  return (
    <Layout>
      <SEO
        title="de effectiviteit van je organisatie vergroten"
        description=" Door inzicht te geven in jouw eigen gedrag en het gedrag van andere mensen in jouw organisatie, kun je de effectiviteit van je organisatie vergroten."
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${kantoor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        {/*<div className="tiny-header centerText">Breinkracht organisatieadvies op basis van de Zipperr® Methode</div>*/}
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Hoe kun je de effectiviteit van je organisatie vergroten?
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Analyseren</p>
        </div>
        <p>
          Via de Zipperr® Methode onderzoeken wij waar jij en je collega's
          energie van krijgen en wat jullie specifieke talenten zijn. Door
          inzicht te geven in jouw eigen gedrag en het gedrag van andere mensen
          in jouw nabijheid. Vanzelfsprekend analyseren wij samen het effect op
          jullie functioneren in het team. Het resultaat van deze analyse zal de
          organisatiestructuur verbeteren met een duurzaam effect.
        </p>
      </div>

      <div className={"containerPad flexy"}>
        <div className={"half block contntr"}>
          <h2>De ReAttach methode is voor iedereen!</h2>
          <p>
            Optimaliseren van ontwikkeling is interessant voor iedereen die
            geïnteresseerd is in persoonlijke groei. Topsport coaches zetten
            ReAttach bijvoorbeeld in om de sportieve prestaties te verbeteren,
            herstel te bevorderen en te kunnen excelleren. Maar ook complexe
            doelgroepen, zoals bij persoonlijkheidsproblematiek, chronische pijn
            of chronische post-traumatische stress heb je baat bij de ReAttach
            methode. Deze is geschikt voor alle professionele disciplines, en
            heeft zich bewezen als effectieve, kwalitatief hoogstaande
            interventie.
          </p>
          <p className={'btn-container'}>
            <Link
              to={"/traject"}
              className="btn primary-lg round expand-on-hover"
            >
             Abonnementen van Zipperr®
            </Link>
          </p>
        </div>
        <div
          className={"half block imger"}
          style={{
            overflow: "hidden",
            backgroundImage: `url(${bgcase})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
        </div>
      </div>
      <div className={"containerPad flexy"}>
        <div className={"half block imger"}

             style={{
               overflow: "hidden",
               backgroundImage: `url(${workwalker})`,
               backgroundSize: "cover",
               backgroundPosition: "center",
               transform: 'scaleX(-1)'
             }}
        >
        </div>

        <div className={"half block"}>
          <h2>
            Analyseer wat jou drijft in je werk of in je persoonlijke leven
          </h2>
          <p>
            Ieder mens heeft zijn eigen drijfveren en waarden. Dingen van
            waaruit jij werkt en leeft. Als jouw waarden in je werk of in je
            persoonlijke leven onvoldoende op elkaar aansluiten, kost dat
            waardevolle energie. Dan raakt het niet je intrinsieke motivatie.
            Door de, door ons toegepaste, persoonlijkheidsanalyse geven wij je
            inzicht in deze drijfveren en waarden, vanuit een wetenschappelijk
            ontwikkeld model de Zipperr® Methode.
          </p>
          <p>
            De Zipperr®Methode helpt daarbij, dankzij een unieke combinatie van
            verschillende mentale interventies en wetenschappelijke technieken
            die succesvol worden toegepast in organisaties en topsport.
          </p>
        </div>
      </div>
      <div className="highlightedText containerPadModified">
        {/*<div className="tiny-header centerText">Breinkracht organisatieadvies op basis van de Zipperr® Methode</div>*/}
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Hoe kun je met de Zipperr® Methode jouw effectiviteit vergroten?
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Analyseren</p>
        </div>
        <p>
          Met behulp met de gepatenteerde Zipperr® koffer® of te wel '
          <b>Zipperr case</b>' maken we een opstelling, waarmee onderlinge
          relaties helder in kaart worden gebracht. Teamgenoten plaatsen pionnen
          en fiches om de dynamiek met collega’s weer te geven. Zo krijgen het
          team op een vriendelijke, toegankelijke en emotioneel veilige manier
          inzicht in hun onbewuste gevoelens en gedachten. In deze stap wordt
          snel duidelijk hoe situaties en relaties op de werkvloer en zelf in
          het persoonlijke leven kunnen verbeteren. Met een laagdrempelige milde
          interventie die zorgt dat stress, belemmeringen en overtuigingen
          reduceren en leerdoelen geactiveerd worden, krijg je een beter
          overzicht en meer controle over je eigen emoties. Deze kortdurende
          mensvriendelijke therapie wordt ook wel de ReAttach methode genoemd en
          heeft de Clinical Neuropsychiatry AWARD 2015 gewonnen. Deze methode
          wordt ingezet voor mensen met o.a. burn-out klachten, depressies,
          slaapproblemen, concentratiestoornissen en chronische posttraumatische
          stress.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImageMobile: file(relativePath: { eq: "briefcase.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageLaptop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageDesktop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 1200, height: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ziperCaseImg: file(relativePath: { eq: "ad-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Organisatieeffectiviteit
